<script>
import DefaultMixin from '../mixin/mixin.js'
import ApresentacaoFotos from "../include/ApresentacaoFotos"
export default {
  name: 'ApresentacaoTelaoBlack1',
  mixins: [DefaultMixin],
  components: {
    ApresentacaoFotos
  },
  data () {
    return {
    }
  }
}
</script>

<template>
  <div v-if="!loading" class="apresentacao-telao black1">
    <div class="col1">
      <div class="lote-info">
        <div class="lote-num">
          <div>LOTE</div>
          <div class="num">{{ lote.numero }}</div>
        </div>
        <div class="lote-desc">
          <div>{{ lote.bem.descricao }}</div>
        </div>
      </div>
      <div class="lote-image">
        <apresentacao-fotos :fotos="fotos" />
      </div>
    </div>
    <div class="col2">
      <div class="data">
        <div class="title">Lance inicial</div>
        <div class="value">R$ {{ lote.valorInicial | moeda }}</div>
      </div>

      <div class="data">
        <div class="title">Maior lance</div>
        <div class="value">R$ {{ valorAtual | moeda }}</div>
      </div>

      <div class="data">
        <div class="title">Tempo</div>
        <div class="value">{{timerPregaoFormatado}}</div>
      </div>

      <div class="data">
        <div class="title">Status</div>
        <div class="value" :class="'lote-status scolor lote-status-' + lote.status">{{ loteStatusString }}</div>
      </div>

      <div class="data">
        <div class="comitente" v-if="lote.bem.comitente">
          <div v-if="lote.bem.comitente.image && lote.bem.comitente.image.thumb"><img width="60%" :src="lote.bem.comitente.image.thumb"></div>
          <div v-else class="font-10 text-center">{{ lote.bem.comitente.pessoa.name }}</div>
        </div>
        <!-- @TODO: URGENTE! Logo leiloeiro -->
      </div>
    </div>
  </div>
  <div class="apresentacao-telao black1 flex flex-center items-center column h-full no-select" style="justify-content: center !important" v-else>
    <div class="m-b-xs">
      <u-spinner-uloc />
    </div>
    Carregando a aplicação
  </div>
</template>

<style src="./estilo.styl" lang="stylus" />
